* {
  margin: 0;
  outline: none;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-image: url("../assets/background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bigraphy {
  text-align: center;
}

.bi-text {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  color: #8d8c8b;
  line-height: 1.5;
}
.desc-container {
  color: #1c1c1c;
  font-family: "Quicksand", sans-serif;
}
.image {
  height: 400px;

  background-image: url("../assets/headerPic.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  transition: transform 0.3s ease-in-out;
  border-radius: 20px;
}

.image:hover {
  transform: scale(1.05);
}
.video-container{
  margin-bottom: 10px;
}
.image-cover {
  overflow: hidden;
  margin: 20px auto;
  height: 390px;
}
.all-content {
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  max-width: 84%;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.videos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: 84%;
  margin: auto;
}
.video {
  border-radius: 8px;
  transition: 0.6s;
}
.video:hover {
  box-shadow: rgba(27, 38, 242, 0.4) 0px 5px, rgba(27, 38, 242, 0.3) 0px 10px,
    rgba(27, 38, 242, 0.2) 0px 15px, rgba(27, 38, 242, 0.1) 0px 20px,
    rgba(27, 38, 242, 0.05) 0px 25px;
}
.content-container {
  align-items: center;
  width: calc(50% - 20px); /* 50% width with gap of 20px between items */
  margin: 10px;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;

  background-color: rgb(255, 255, 255);
  max-width: 290px;
  min-width: 290px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.2s ease-in-out;
}
.content-container:hover {
  background-color: rgb(215, 247, 255);

  transform: translateY(1px);
  box-shadow: rgba(99, 99, 99, 0.2);
}
.bigraphy {
  justify-content: center;
  padding: 20px;
}
.video-title {
  font-weight: bold;
  padding-bottom: 7px;
  font-size: 22px;
  text-align: center;
}

.videos-title {
  text-align: center;
  text-shadow: 0 0 4px #abacac;
  margin-bottom: 10px;
}
.website-title {
  text-align: center;
  font-size: 3em;
  transition: 0.2s ease-in-out;
}

.website-title:hover {
  color: rgb(111, 175, 206);
}

@media screen and (max-width: 600px) {
  .content-container {
    width: calc(100% - 20px); /* Full width on smaller screens */
  }
}

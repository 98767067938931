.navbar {
  background-color: #053b7e7b;
  padding: 8px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.nav-menu {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
}

.nav-item {
  margin-right: 30px;
}

.nav-item a {
  text-decoration: none;
  color: #e9e9e9;
  font-weight: bold;
  position: relative;
  transition: 0.2s ease-in-out;
}

.nav-item a:hover {
  color: #2b719f;
}

.nav-item a::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
  border-radius: 8px;
  background: #ababab;
  position: absolute;
  bottom: -3px;
  left: 0;
  transition: width 0.3s;
}

.nav-item a:hover::after {
  width: 100%;
}

.login-button {
  text-decoration: none;
  color: #e9e9e9;
  font-weight: bold;
  border: 2px solid #e9e9e9;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.login-button:hover {
  background-color: #2b719f;
  color: #fff;
}
.logout-button {
  background-color: #f7929c;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 22px;
}

.logout-button:hover {
  background-color: #c0392b;
}